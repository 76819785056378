import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Section from "../components/Section"
import { Body, Bold, Caption, Header2 } from "../components/Typography"
import Button from "../components/Button"
import SectionHeader from "../components/SectionHeader"
import MainPoint from "../components/MainPoint"

import { GatsbyImage } from "gatsby-plugin-image"

import styled from "styled-components"
import { FiArrowRight } from "react-icons/fi"
import opengraph from "../images/opengraph.png"

const AboutGrid = styled.div`
  display: grid;
  gap: 32px;
  padding-top: 32px;

  grid-template-columns: repeat(3, 1fr);

  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`

const AboutCardWrapper = styled.div`
  position: relative;
  padding-top: 100%;
`
const AboutCardText = styled.div`
  position: absolute;
  left: 8px;
  right: 8px;
  bottom: 8px;
  top: calc(100% - 60px - 32px - 8px);
  padding: 16px;
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(12px);
  background: rgba(255, 255, 255, 0.01);
  transition: top 300ms ease-in-out;
  overflow: hidden;
  border-radius: 2px;

  > * :last-child,
  > * :nth-child(3) {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
  }

  > * :first-child,
  > * :nth-child(2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    flex-shrink: 0;
    margin-bottom: 8px;
  }

  :before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: var(--base000);
    opacity: 0.75;
    z-index: -1;
  }

  ${AboutCardWrapper}:hover & {
    top: 8px;

    > * {
      opacity: 1;
    }
  }
`
const AboutCardImage = styled(GatsbyImage)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -2;

  border-radius: 2px;
  overflow: hidden;

  > * {
    border-radius: 2px;
    overflow: hidden;
  }
`

const AboutCard = props => {
  return (
    <Link to={props.url}>
      <AboutCardWrapper>
        <AboutCardImage image={props.image} style={{ position: "absolute" }} />
        <AboutCardText>
          <Bold style={{ color: "var(--base100)" }}>{props.name}</Bold>

          <Body style={{ color: "var(--base070)" }}>{props.position}</Body>
          <Body>{props.blurb}</Body>
          <div
            style={{
              display: "inline-grid",
              gridAutoFlow: "column",
              gap: 8,
              alignItems: "end",
              justifyContent: "end",
              paddingRight: 20,
              paddingTop: 8,
              height: "100%",
            }}
          >
            <Caption style={{ color: "var(--base070)" }}>READ FULL BIO</Caption>
            <FiArrowRight style={{ color: "var(--base070)" }} />
          </div>
        </AboutCardText>
      </AboutCardWrapper>
    </Link>
  )
}

function AboutPage({ data }) {
  return (
    <Layout pageTitle="About" image={opengraph}>
      <Section>
        <div style={{ display: "grid", gridAutoFlow: "row", gap: 32 }}>
          <SectionHeader>Who We Are</SectionHeader>
          <Header2>CueBack at its core</Header2>
          <Body>
            We specialize in helping universities strengthen the emotional
            connection and alumni identity that alumni have with their alma
            mater. Why is that important? Because they are two proven drivers of
            giving. There are 1.7 million nonprofits competing for your alumni’s
            philanthropic dollars. Our solution is purpose-built to turn
            engagement into giving. We leverage academic and scientific
            research, such as nostalgia and storytelling, to provide your alumni
            with a personalized engagement experience that is also specifically
            designed to uncover actionable cultivation insights.
          </Body>
        </div>
      </Section>
      <Section>
        <MainPoint
          text={[
            <Header2>A message from the founders</Header2>,
            <Body>
              As a first-generation scholarship student, I founded CueBack
              because I’m a big believer in the value of education. My mother
              was a non-English speaking migrant to Australia who brought up
              four children by herself. I would not be where I am today if I
              hadn’t had the opportunity to attend university.
            </Body>,
            <Body>
              After listening to many people in advancement, I realized there
              are many similarities in the work done by gift officers and my own
              experience with relationship-selling in the for-profit world. I
              asked my son to help me start CueBack and add his technology
              expertise to our intergenerational team.
            </Body>,
            <Button
              primary
              style={{ justifySelf: "start" }}
              url="/about/our-background"
            >
              Read More <FiArrowRight />
            </Button>,
          ]}
          image={data.daniel_arnie}
        />
      </Section>
      <Section>
        <SectionHeader>Our Team</SectionHeader>
        <AboutGrid>
          <AboutCard
            image={data.arnie.nodes[0].childImageSharp.gatsbyImageData}
            name="Arnie Papp"
            position="CEO & Co-founder"
            url="/about/arnie"
            blurb="Arnie’s global background in startups and sales has inspired his many endeavors today."
          />
          <AboutCard
            image={data.daniel.nodes[0].childImageSharp.gatsbyImageData}
            name="Daniel Papp"
            position="COO & Co-founder"
            url="/about/daniel"
            blurb="Daniel’s technological and fundraising know-how helps propel CueBack into the future."
          />
          {/* <AboutCard
            image={data.bruce.nodes[0].childImageSharp.gatsbyImageData}
            name="Bruce Grant"
            position="CRO"
            url="/about/bruce"
          />
          <AboutCard
            image={data.steve.nodes[0].childImageSharp.gatsbyImageData}
            name="Steve Buchanan"
            position="CTO"
            url="/about/steve"
          /> */}
          <AboutCard
            image={data.travis.nodes[0].childImageSharp.gatsbyImageData}
            name="Dr. J. Travis McDearmon"
            position="Advisory Board Member"
            url="/about/travis"
            blurb="Dr. McDearmon uses his expertise in the higher education advancement industry to help others."
          />
          <AboutCard
            image={data.bob.nodes[0].childImageSharp.gatsbyImageData}
            name="Bob Finnel"
            position="Advisory Board Member"
            url="/about/bob"
            blurb="Bob has over 30 years of experience of legal counseling for several technology companies."
          />
        </AboutGrid>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query {
    arnie: allFile(filter: { relativePath: { eq: "arnie.jpeg" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: CONSTRAINED
            width: 800
            aspectRatio: 1
          )
        }
      }
    }
    daniel: allFile(filter: { relativePath: { eq: "daniel-1.jpg" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: CONSTRAINED
            width: 800
            aspectRatio: 1
          )
        }
      }
    }
    bruce: allFile(filter: { relativePath: { eq: "bruce.png" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: CONSTRAINED
            width: 800
            aspectRatio: 1
          )
        }
      }
    }
    steve: allFile(filter: { relativePath: { eq: "steve.png" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: CONSTRAINED
            width: 800
            aspectRatio: 1
          )
        }
      }
    }
    travis: allFile(filter: { relativePath: { eq: "travis.png" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: CONSTRAINED
            width: 800
            aspectRatio: 1
          )
        }
      }
    }
    bob: allFile(filter: { relativePath: { eq: "bob.png" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: CONSTRAINED
            width: 800
            aspectRatio: 1
          )
        }
      }
    }
    daniel_arnie: allFile(
      filter: { relativePath: { eq: "daniel+arnie.png" } }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: CONSTRAINED)
        }
      }
    }
  }
`

export default AboutPage
